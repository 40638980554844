<template>
  <v-window v-model="window" class="subscription-choose" vertical>
    <v-window-item vertical>
      <v-sheet color="#373e4c" class="negative-margin">
        <v-container>
          <v-row class="text-center">
            <v-col cols="12"
              ><h2 class="font-nunito text-center white--text">{{ $vuetify.lang.t('Select your plan type') }}</h2></v-col
            >
            <v-col cols="12"
              ><h3 class="font-nunito text-center white--text">
                {{ `and get access to  FlightScope Cloud ${sportType}` }}
              </h3></v-col
            >
          </v-row>
          <v-row>
            <plan-list-table
              v-model="selectedPlan"
              :show-top-actions="!$vuetify.breakpoint.mdAndUp"
            ></plan-list-table>
          </v-row>
        </v-container>
      </v-sheet>
      <div class="white positive-spacer"></div>
    </v-window-item>
  </v-window>
</template>

<script>
import capitalize from 'lodash/capitalize';
import { FormRules, preSubscriptionRedirect } from '../tools/helpers';

import HasPlans from '../../service-plan/mixins/HasPlans';

const PlanListTable = () => import('../../service-plan/components/PlanListTable.vue');

export default {
  name: 'SubscriptionChoose',

  mixins: [
    HasPlans,
  ],

  components: {
    PlanListTable,
  },
  data() {
    return {
      window: 0,

      selectedPlan: null,

      loading: true,

      rules: FormRules,
    };
  },

  computed: {
    sportType() {
      return capitalize(process.env.VUE_APP_SPORT_TYPE);
    },
    target() {
      const { targetSubscription } = this.$store.state.user?.profile?.Parameters ?? {};
      return targetSubscription;
    },
    currentPlan() {
      return this.isDataReady && this.target
        ? this.registerServicePlans.find((plan) => plan.level === this.target)
        : null;
    },
  },

  methods: {
    // TODO: figure out if save the selected Plan to user object
    redirectOnSelection(plan) {
      if (plan) {
        preSubscriptionRedirect(this, plan.level);
      }
    },
  },

  mounted() {
    if (this.currentPlan) {
      this.selectedPlan = this.currentPlan;
    }
  },

  watch: {
    selectedPlan: {
      handler: 'redirectOnSelection',
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-choose {
  --negative-m: 200px;
}

.negative-margin {
  margin-bottom: calc(-1 * var(--negative-m));
}

.positive-spacer {
  width: 100%;
  padding-top: var(--negative-m);
}

h2,
h3 {
  letter-spacing: 0.5px;
  font-weight: 300;
}

h2 {
  font-size: 72px;
  text-decoration: none solid rgb(255, 255, 255);
  margin-top: 58px;
}

h3 {
  font-size: 35px;
  text-decoration: none solid rgb(245, 245, 245);
  color: #f5f5f5;
  margin-bottom: 58px;
}

.v-window-item {
  max-height: calc(100vh - 60px);
}
</style>
